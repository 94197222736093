// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import LoginDocente from './pages/LoginDocente';
import Register from './pages/Register';
import Layout from './components/Layout';
import ForgotPassword from './pages/ForgotPassword'; // Importa la página de recuperación
import StudentCourses from './pages/StudentCourses'; // Importa la página de cursos
import CourseSearch from './components/CourseSearch'; // Asegúrate de que la ruta sea correcta
import CourseDetail from './components/CourseDetail'; // Importa el componente CourseDetail
import Telemedicina from './pages/Telemedicina';
import Carousel from './components/Carousel';
import './components/Carousel.css'; // Asegúrate de que la ruta sea correcta

import { createTheme, ThemeProvider } from '@mui/material/styles';
import ExamDetails from './pages/ExamDetails';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1E1E2F', // Cambia este valor por el color que deseas
    },
    secondary: {
      main: '#0096D6', // Cambia este valor si deseas personalizar el color secundario
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Carousel /><Home /></Layout>} />
        <Route path="/login" element={<Layout><Login /></Layout>} />
        <Route path="/loginDocente" element={<Layout><LoginDocente /></Layout>} />

        <Route path="/register" element={<Layout><Register /></Layout>} />
        <Route path="/forgot-password" element={<Layout><ForgotPassword /></Layout>} /> {/* Nueva ruta */}     
        <Route path="/courses" element={<Layout><StudentCourses /></Layout>} /> {/* Nueva ruta */}
        <Route path="/search-courses" element={<Layout><CourseSearch /></Layout>} />
        <Route path="/course/:courseId" element={<Layout><CourseDetail /></Layout>} /> {/* Ruta dinámica */}
        <Route path="/:courseName" element={<Layout><Telemedicina /></Layout>} />
        
        
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
