import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import {Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent, LinearProgress,
  Button,
} from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExamDetails from "./ExamDetails"; // Asegúrate de tener este componente
import axios from "axios";

export default function CourseExams() {
  const [exams, setExams] = useState([]);
  const [selectedExamId, setSelectedExamId] = useState(null);
  const navigate = useNavigate();

  // Lógica para obtener exámenes del backend
  useEffect(() => {
    const fetchExams = async () => {
      try {
        const response = await axios.get("http://127.0.0.1:5000/api/exams");
        setExams(response.data);
      } catch (error) {
        console.error("Error al cargar exámenes:", error);
      }
    };

    fetchExams();
  }, []);

  console.log("exams",exams)
  const navigateToExam = (examId) => {
    setSelectedExamId(examId); // Establece el ID del examen seleccionado
    localStorage.setItem('examId', examId);
  };

  const completionPercentage =
  (exams.filter((exam) => exam.completed).length / exams.length) * 100;
  
  return (
    <Box sx={{border:0,display:"flex", flexDirection:{xs:"column",md:"row",lg:"row"},
    height:"88%", width:"100%",marginTop:2,gap:2.5,overflowY: 'auto'}}>
      <Box sx={{display:"flex",flexDirection:"column",height:{xs:"70%",md:"100%",lg:"100%"},border:0, width:"70%"}}>

      {selectedExamId ? (
        // Renderiza los detalles del examen si hay un `examId`
        <ExamDetails examId={selectedExamId} />
      ) : (
        // Renderiza la lista de exámenes si no hay un `examId`
        <>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ margin: 2, textAlign: "left" }}
          >
            <QuizIcon
              fontSize="large"
              sx={{ verticalAlign: "middle", marginRight: 2 }}
            />
            Exámenes Disponibles
          </Typography>
          <Grid container spacing={3} sx={{  alignContent: "center" }}>
          
            {exams.map((exam) => (
              
              <Grid item xs={12} md={11} lg={10} key={exam.id} sx={{ margin: 2, textAlign: "left" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ margin: 0, textAlign: "left" }}
                >
                    {exam.seccion_title}
                </Typography>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    marginTop: 1,
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {exam.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {exam.description}
                    </Typography>
                  </CardContent>
                  <Button
                    variant="contained"
                    endIcon={<ArrowForwardIosIcon />}
                    sx={{ margin: "auto 16px 16px" }}
                    onClick={() => navigateToExam(exam.id)}
                  >
                    Iniciar Examen
                  </Button>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
     
      <Box sx={{display:"flex", border: '1px solid #ccc', borderRadius:3, boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
        height:{xs:"30%",md:"20%",lg:"20%"},width:{xs:"100%",md:"27%",lg:"27%"},flexDirection:"column",alignItems:"center"}}>
        
        <Box sx={{display:"flex", border:0, borderRadius:3,
          height:{xs:"30%",md:"20%",lg:"20%"},width:"90%",flexDirection:"column",marginTop:1}}>
          <Typography variant="h6" gutterBottom>
            Progreso de exámenes
          </Typography>
          <LinearProgress variant="determinate" value={completionPercentage} />
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {completionPercentage.toFixed(0)}% completado
          </Typography>
        </Box>
      </Box>
   
   </Box>
  );
}
