// ForumPage.js
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, List, ListItem, ListItemText, Divider } from '@mui/material';

const ForumPage = ({ userId, courseId }) => {
  // Estado para los temas y sus publicaciones
  const [topics, setTopics] = useState([
    {
      id: 1,
      title: 'Problemas con el curso',
      description: 'Discute los problemas que encuentras con el curso y comparte tus soluciones.',
      posts: [
        { id: 1, userId: 1, content: 'Estoy teniendo problemas con la parte de intro.' },
        { id: 2, userId: 2, content: 'A mí me pasa lo mismo, especialmente con las operaciones.' },
      ],
    },
    {
      id: 2,
      title: 'Sugerencias para mejorar el curso',
      description: 'Comparte tus sugerencias para mejorar la calidad del curso.',
      posts: [
        { id: 1, userId: 1, content: 'Agregar más ejemplos prácticos sería muy útil.' },
      ],
    },
  ]);

  const [newTopicTitle, setNewTopicTitle] = useState('');
  const [newTopicDescription, setNewTopicDescription] = useState('');
  const [newPostContent, setNewPostContent] = useState('');
  const [selectedTopic, setSelectedTopic] = useState(null);

  // Crear un nuevo tema
  const handleCreateTopic = () => {
    if (newTopicTitle.trim() && newTopicDescription.trim()) {
      const newTopic = {
        id: topics.length + 1,
        title: newTopicTitle,
        description: newTopicDescription,
        posts: [],
      };
      setTopics([...topics, newTopic]);
      setNewTopicTitle('');
      setNewTopicDescription('');
    }
  };

  // Crear una nueva publicación dentro de un tema
  const handleCreatePost = (topicId) => {
    if (newPostContent.trim()) {
      const updatedTopics = topics.map((topic) =>
        topic.id === topicId
          ? {
              ...topic,
              posts: [
                ...topic.posts,
                { id: topic.posts.length + 1, userId, content: newPostContent },
              ],
            }
          : topic
      );
      setTopics(updatedTopics);
      setNewPostContent('');
    }
  };

  return (
    <Box sx={{ display:"flex",height:"80%", padding: 2, backgroundColor: '#f5f5f5', borderRadius: 2 }}>
     <Box sx={{ display:"flex",borderRight: '4px solid #1976d2',flexDirection:"column" ,height:"80%", width:"20%",padding: 3, backgroundColor: '#f5f5f5', borderRadius: 2 }}>
    
      <Typography variant="h4" gutterBottom>
        Foro del curso
      </Typography>

      {/* Crear nuevo tema */}
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h6">Crear un nuevo tema</Typography>
        <TextField
          label="Título del tema"
          variant="outlined"
          fullWidth
          value={newTopicTitle}
          onChange={(e) => setNewTopicTitle(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Descripción del tema"
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          value={newTopicDescription}
          onChange={(e) => setNewTopicDescription(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleCreateTopic}>
          Crear tema
        </Button>
      </Box>
      </Box>



      {/* Listado de temas */}
      <List>
        {topics.map((topic) => (
          <ListItem
            key={topic.id}
            sx={{
              backgroundColor: '#fff',
              marginBottom: 2,
              padding: 2,
              borderRadius: 1,
              cursor: 'pointer',
              '&:hover': { backgroundColor: '#e1e1e1' },
            }}
            onClick={() => setSelectedTopic(topic.id)}
          >
            <ListItemText
              primary={topic.title}
              secondary={topic.description}
              sx={{
                '& .MuiListItemText-primary': {
                  fontWeight: 'bold',
                },
              }}
            />
          </ListItem>
        ))}
      </List>

      {/* Mostrar publicaciones del tema seleccionado */}
      {selectedTopic && (
        <Box sx={{ marginTop: 3, marginLeft:6 }}>
          <Typography variant="h6">Publicaciones</Typography>
          <List>
            {topics
              .find((topic) => topic.id === selectedTopic)
              .posts.map((post) => (
                <ListItem key={post.id} sx={{ backgroundColor: '#fff', marginBottom: 1, borderRadius: 1 }}>
                  <ListItemText
                    primary={`Usuario ${post.userId}:`}
                    secondary={post.content}
                    sx={{
                      '& .MuiListItemText-primary': {
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </ListItem>
              ))}
          </List>

          {/* Formulario para agregar una nueva publicación */}
          <TextField
            label="Nueva publicación"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={newPostContent}
            onChange={(e) => setNewPostContent(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleCreatePost(selectedTopic)}
          >
            Publicar
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ForumPage;
