import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Chip,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

const Homework = ({ userId, courseId }) => {
  const [tasks, setTasks] = useState([
    { id: 1, title: "Lección 1: Introducción", dueDate: "2024-11-22", completed: false, priority: "Alta" },
    { id: 2, title: "Ejercicio 1: Resolver problemas básicos", dueDate: "2024-11-23", completed: true, priority: "Media" },
    { id: 3, title: "Examen parcial", dueDate: "2024-11-25", completed: false, priority: "Alta" },
  ]);
  const [filter, setFilter] = useState("Todas");
  const [openModal, setOpenModal] = useState(false);
  const [newTask, setNewTask] = useState({ title: "", dueDate: "", priority: "Baja" });

  const handleAddTask = () => {
    setTasks([...tasks, { id: tasks.length + 1, ...newTask, completed: false }]);
    setNewTask({ title: "", dueDate: "", priority: "Baja" });
    setOpenModal(false);
  };

  const filteredTasks = tasks.filter((task) =>
    filter === "Todas"
      ? true
      : filter === "Pendientes"
      ? !task.completed
      : task.completed
  );

  const completionPercentage =
    (tasks.filter((task) => task.completed).length / tasks.length) * 100;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Tareas del Curso
      </Typography>

      {/* Indicador de progreso */}
      <Typography variant="subtitle1" gutterBottom>
        Progreso del curso
      </Typography>
      <LinearProgress variant="determinate" value={completionPercentage} />
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {completionPercentage.toFixed(0)}% completado
      </Typography>

      {/* Filtros */}
      <Grid container spacing={2} alignItems="center" style={{ margin: "20px 0" }}>
        <Grid item>
          <Button
            variant={filter === "Todas" ? "contained" : "outlined"}
            onClick={() => setFilter("Todas")}
          >
            Todas
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={filter === "Pendientes" ? "contained" : "outlined"}
            onClick={() => setFilter("Pendientes")}
          >
            Pendientes
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={filter === "Completadas" ? "contained" : "outlined"}
            onClick={() => setFilter("Completadas")}
          >
            Completadas
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
            Añadir Tarea
          </Button>
        </Grid>
      </Grid>

      {/* Lista de tareas */}
      <Grid container spacing={3}>
        {filteredTasks.map((task) => (
          <Grid item xs={12} sm={6} md={4} key={task.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{task.title}</Typography>
                <Typography color="textSecondary">Fecha límite: {task.dueDate}</Typography>
                <Chip
                  label={task.priority}
                  color={
                    task.priority === "Alta"
                      ? "error"
                      : task.priority === "Media"
                      ? "warning"
                      : "default"
                  }
                  style={{ marginTop: 10 }}
                />
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color={task.completed ? "success" : "primary"}
                  onClick={() =>
                    setTasks((prev) =>
                      prev.map((t) =>
                        t.id === task.id ? { ...t, completed: !t.completed } : t
                      )
                    )
                  }
                >
                  {task.completed ? "Marcar como Pendiente" : "Completar"}
                </Button>
                <Button size="small" color="secondary">
                  Editar
                </Button>
                <Button
                  size="small"
                  color="error"
                  onClick={() => setTasks((prev) => prev.filter((t) => t.id !== task.id))}
                >
                  Eliminar
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal para añadir tarea */}
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Añadir Nueva Tarea</DialogTitle>
        <DialogContent>
          <TextField
            label="Título"
            fullWidth
            margin="dense"
            value={newTask.title}
            onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
          />
          <TextField
            label="Fecha Límite"
            type="date"
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
            value={newTask.dueDate}
            onChange={(e) => setNewTask({ ...newTask, dueDate: e.target.value })}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Prioridad</InputLabel>
            <Select
              value={newTask.priority}
              onChange={(e) => setNewTask({ ...newTask, priority: e.target.value })}
            >
              <MenuItem value="Alta">Alta</MenuItem>
              <MenuItem value="Media">Media</MenuItem>
              <MenuItem value="Baja">Baja</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleAddTask} color="primary">
            Añadir
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Homework;
