import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Link,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem,ListItemText,
  List,
  Snackbar, 
  Alert,
  Drawer,
  TextField, useMediaQuery
} from '@mui/material';
import Button from '@mui/material/Button';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CourseProgress from './CourseProgress';  // Asegúrate de que la ruta sea correcta
import ForumPage from './ForumPage';
import Homework from './Homework';
import CourseExams from './CourseExams';
import { useTheme } from '@mui/material/styles';

const Telemedicina = () => {
  const mycourseId = localStorage.getItem('mycourseId');
  const mycourseName = localStorage.getItem('mycourseName');
  const userId = localStorage.getItem('userId');
  const courseWelcome = localStorage.getItem('mycourseWelcome');
  const courseProfesor = localStorage.getItem('mycourseProfessor');
  const userName = localStorage.getItem('userName');

  const [selectedTab, setSelectedTab] = useState(0);
  const [sections, setSections] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
  const [classes, setClasses] = useState([]);
  const [selectedSection, setSelectedSection] = useState(0);
  

  const [contenidoC, setcontenidoC] = useState(false);
  const [actualClassName, setActualClassName] = useState("");
  const [actualSectionName, setActualSectionName] = useState("");
  const [actualBD, setActualBD] = useState("");

  const handleAssignValues = (a, b, c) => {
     setActualClassName(a);
    setActualSectionName(b);
    setActualBD(c);
    console.log(actualClassName, actualSectionName, actualBD);
    };
  
  const theme =  useTheme({
    breakpoints: {
      values: {
        xxs: 0,    // Tamaño más pequeño que xs
        xs: 360,   // Pequeños dispositivos móviles
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detecta pantallas menores a 'sm' (generalmente móviles)
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const handleMobileButtonClick = () => {
    // Lógica para manejar el clic en el botón en móvil
    setDrawerOpen(true);
    // Puedes abrir un modal, un drawer, o implementar cualquier lógica aquí.
  };
  const handleCloseDrawer = () => {
    setDrawerOpen(false); // Cierra el Drawer
  };

  // Obtener las secciones y clases del curso
  useEffect(() => {
    if (mycourseId) {
      const fetchCourseData = async () => {
        try {
          const responseSections = await axios.get(`https://universidadvirtualdelperu.edu.pe/myapp/api/sections/${mycourseId}`);
          const sectionsData = responseSections.data || [];
          const totalNumClasses = sectionsData.reduce((sum, item) => sum + item.num_classes, 0);
          localStorage.setItem('clases_length', totalNumClasses);
          
          // Para cada sección, cargar las clases asociadas
          const updatedSections = await Promise.all(
            sectionsData.map(async (section) => {
              const responseClasses = await axios.get(`https://universidadvirtualdelperu.edu.pe/myapp/api/classes/${section.id}`);
              return { ...section, classes: responseClasses.data || [] };
            })
          );

          setSections(updatedSections);
        } catch (error) {
          console.error('Error al cargar datos del curso:', error);
        }
      };

      fetchCourseData();
    }
  }, [mycourseId]);

  const handleClassClick = (classId) => {
    setSelectedClass(classId);
  };
  
  const firstClassId = sections
  .flatMap((section) => section.classes)[0]?.id;
  localStorage.setItem('firstclassid',firstClassId);
 
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedClass(null);
  };

  const handleNextClass = () => {
    const currentSection = sections.find((section) =>
      section.classes.some((cls) => cls.id === selectedClass)
    );
    const currentIndex = currentSection.classes.findIndex((cls) => cls.id === selectedClass);
    if (currentIndex < currentSection.classes.length - 1) {
      setSelectedClass(currentSection.classes[currentIndex + 1].id);
    }
  };

  const handlePreviousClass = () => {
    const currentSection = sections.find((section) =>
      section.classes.some((cls) => cls.id === selectedClass)
    );
    const currentIndex = currentSection.classes.findIndex((cls) => cls.id === selectedClass);
    if (currentIndex > 0) {
      setSelectedClass(currentSection.classes[currentIndex - 1].id);
    }
  };

/*********************************************************** */

const [messages, setMessages] = useState([
  { sender: 'bot', text: '¡Hola! ¿En qué puedo ayudarte hoy?' }
]);
const [input, setInput] = useState('');
const [isGenerating, setIsGenerating] = useState(false);


const handleSend = useCallback(async () => {
  if (input.trim() === "") return;

  setIsGenerating(true); // Activar estado de carga

  const cursoInfo = {
    docente: courseProfesor,
    curso: mycourseName,
    seccion: actualSectionName,
    clase: actualClassName,
    estudiante: userName,
    ppt: actualBD,
  };

  const newMessage = { sender: "user", text: input };
  setMessages((prevMessages) => [...prevMessages, newMessage]);

  try {
    const requestBody = {
      pregunta: input,
      infoCurso: cursoInfo,
    };

    const response = await fetch(
      "https://7ae0-200-106-49-148.ngrok-free.app/preguntar",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      }
    );

    if (!response.body) throw new Error("No se recibió respuesta del servidor.");

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let assistantMessage = { sender: "assistant", text: "" };
    setMessages((prevMessages) => [...prevMessages, assistantMessage]);

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value);
      assistantMessage.text += chunk;

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.sender === "assistant" && msg.text === ""
            ? { ...msg, text: assistantMessage.text }
            : msg
        )
      );
    }
  } catch (error) {
    console.error("Error al consultar el endpoint:", error);

    const errorMessage = {
      sender: "assistant",
      text: "Hubo un problema al procesar tu consulta. Inténtalo nuevamente.",
    };
    setMessages((prevMessages) => [...prevMessages, errorMessage]);
  } finally {
    setIsGenerating(false); // Desactivar estado de carga
    setInput("");
  }
}, [
  input,
  courseProfesor,
  userName,
  setMessages,
  setInput,
  actualClassName,
  actualSectionName,
  actualBD,
]);


/************************************************* */
  const renderContent = (content) => {
    if (!content) {
      return <Typography variant="body1" align="center">Contenido no disponible.</Typography>;
    }

    // Google Drive
    if (content.includes('drive.google.com')) {
      return (
        <iframe
          title="Presentación"
          src={content}
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      );
    }

    // YouTube
    if (content.includes('youtube.com') || content.includes('youtu.be')) {
      const videoId = content.includes('youtube.com')
        ? new URL(content).searchParams.get('v')
        : content.split('/').pop();
      return (
        <iframe
          title="Video"
          src={`https://www.youtube.com/embed/${videoId}`}
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      );
    }


    //video de la carpeta
    if (content.includes('/videoClases')) {
      return (
        
        <iframe
          title="Presentación"
          src={content}
          width="100%"
          height="79%"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      );
    }
    
    // Genially
    if (content.includes('genially.com')) {
      return (
        <iframe
          title="Contenido Interactivo"
          src={content}
          style={{
            width: '100%',
            height: '100%',
          }}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      );
    }

    
    // Enlace genérico
    return (
      <Typography variant="body2" align="center">
        Contenido no reconocido.
      </Typography>
    );
  };

/************************************************* */
const [timeOld, setTimeOld] = useState({
  now: "",
  hours: "",
  minutes: "",
  seconds: "",
});

const [timeNew, setTimeNew] = useState({
  now: "",
  hours: "",
  minutes: "",
  seconds: "",
});

const [timeClass, setTimeClass] = useState({
  now: "",
  hours: "",
  minutes: "",
  seconds: "",
});



  const getTime = () => {
    const now = new Date(); // Obtiene la fecha y hora actual
    const hours = now.getHours(); // Horas actuales
    const minutes = now.getMinutes(); // Minutos actuales
    const seconds = now.getSeconds(); // Segundos actuales
    // Guardar las variables en el estado
    return{
      now: now.toString(),
      hours,
      minutes,
      seconds,
    };
  };
  const [oldCls, setOldCls] = useState("");
  const oldValue = (cls) => {
    const newTime = getTime(); // Obtenemos el tiempo actual
    setTimeOld(newTime); // Establecemos el nuevo tiempo en timeOld
    console.log(`Tiempo ${cls}`); // Esto va a funcionar correctamente
    setOldCls(cls);
  }

  const calculateTimeclass = () => {
    if (!timeOld || !timeOld.seconds) {
      console.log("timeOld no está definido correctamente");
      return;
    }
    const timeNew = getTime();
  
  let updatedTimeClass = { ...timeClass }; // Copia de timeClass para actualizarla inmutablemente
  updatedTimeClass.seconds = timeNew.seconds - timeOld.seconds;
  updatedTimeClass.minutes = timeNew.minutes - timeOld.minutes;
  updatedTimeClass.hours = timeNew.hours - timeOld.hours;

  
  if (updatedTimeClass.hours < 0) {
    updatedTimeClass.hours += 24;
    if (updatedTimeClass.minutes < 0) {
      updatedTimeClass.minutes += 60;
      updatedTimeClass.hours -= 1;
      if (updatedTimeClass.seconds < 0) {
        updatedTimeClass.seconds += 60;
        updatedTimeClass.minutes -= 1;
      }
    }
  }

  if (updatedTimeClass.minutes < 0) {
    updatedTimeClass.minutes += 60;
    updatedTimeClass.hours -= 1;
    if (updatedTimeClass.seconds < 0) {
      updatedTimeClass.seconds += 60;
      updatedTimeClass.minutes -= 1;
    }
  }

  if (updatedTimeClass.seconds < 0) {
    updatedTimeClass.seconds += 60;
    updatedTimeClass.minutes -= 1;
  }
  setTimeClass(updatedTimeClass);
  setTimeOld(timeNew);
  
};

const saveTimeToDatabase = async (classId) => {
  const totalTime = timeClass.hours * 3600 + timeClass.minutes * 60 + timeClass.seconds; // Tiempo total en segundos
  const status = totalTime > 60 * 5 ? "true" : "false"; // Estado: "true" si el tiempo supera los 5 minutos
 
  const old_IdClass = oldCls.id || oldCls; // Asegúrate de que oldCls sea un ID válido
    const updatedidClass = classId.id || classId; // Extraer el ID si es un objeto

  const timeData = {
    user_id: userId,
    course_id: mycourseId,
    class_id: old_IdClass,
    time: totalTime,
    status: status,
    date: new Date().toISOString() // Fecha de guardado (en formato ISO)
  };
   
  setOldCls(updatedidClass);
  console.log("Datos enviados al servidor:", timeData);
  try {
    const response = await axios.post("https://universidadvirtualdelperu.edu.pe/myapp/save-time", timeData);
    console.log(response.data); // Muestra la respuesta del servidor
  } catch (error) {
    console.error("Error al guardar el tiempo:", error);
  }
};
  /************************************************* */
  if (!mycourseId || !userId) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography variant="h5" color="error">
          Error: Información del curso no disponible.
        </Typography>
      </Box>
    );
  }
/**************************************************26 Nov */

/*****************************************************3* */
  return (
    <Box sx={{ display: 'flex',height:{xs:'140vh',md:'140vh',lg:'140vh'} ,width: '100%' }}>
      
      {/* Contenido principal */}
      <Box sx={{ flexGrow: 1, padding:{xs:1,md:3,lg:3}, backgroundColor: '#f5f5f5',height: {xs:"102%",md:"100%",lg:'138.5vh'}, // Más altura
                  width: '100%',  }}>
        <Typography variant="h5" align="left" gutterBottom fontWeight={400}>
          {mycourseName || 'Curso'}
        </Typography>

        <Tabs sx={{ borderBottom: '1px solid #ccc',  }} value={selectedTab} onChange={handleTabChange} left>
          <Tab label="Curso" />
          <Tab label="Progreso" />
          <Tab label="Foro" />
          <Tab label="Tareas" />
          <Tab label="Exámenes" />
        </Tabs>

      {/* Contenido de la pestaña Progreso */}
    
    {/* Contenedor principal con diseño de dos columnas */}
    <Box sx={{ display: 'flex', flexDirection:{sm:"row",xs:'column', md:'row',lg:'row'},height:{xs:"93%",md:"90%",lg:"100%"}, gap:{xs:2,md:4,lg:4}}}>
    {/* Drawer de secciones y clases */}   
    {selectedTab === 0  ? ( selectedClass ?(
          <>
          {isMobile ? (
          <> {/* Para celulares */}
          <Button
              variant="contained"
              onClick={handleMobileButtonClick}
              sx={{ position: 'relative',  width: "96%",  /* Ancho del 50% del contenedor */
                height: "4%", marginTop:1,  }}
            >
              Secciones
            </Button> 
            <Drawer
             anchor="left" // Puedes cambiar a "right", "top", o "bottom" según tu diseño
              open={isDrawerOpen}
              onClose={handleCloseDrawer}
              sx={{
              '& .MuiDrawer-paper': {
                width: '250px', // Ancho del Drawer
                backgroundColor: '#1E1E2f', // Color de fondo
                color: '#1E1E2F', // Color del texto
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Sombra
                },
              }}
               >
              <List>
            {sections.length === 0 ? (
                <Typography>No hay secciones disponibles.</Typography>
                ) : (
                sections.map((section, index) => (
                  <Accordion
                  key={section.id}
                  expanded={selectedSection === index}
                  onChange={() => {setSelectedSection(index); actualSectionName=section.title}}
                  sx={{ marginBottom: 1 }}
                  >
                <AccordionSummary>
                  <Typography variant="body3">{section.title || section.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {section.classes && section.classes.length > 0 ? (
                      section.classes.map((cls) => (
                        <ListItem
                          key={cls.id}
                          button
                          onClick={() => {setSelectedClass(cls.id);setcontenidoC(true); calculateTimeclass(cls.id);
                            saveTimeToDatabase(cls.id);handleAssignValues (section.title, cls.title, cls.DB)}}
                          sx={{
                            paddingLeft: 2,
                            backgroundColor: selectedClass === cls.id ? '#e0ee0' : 'transparent',
                            borderRadius: 1,
                            marginBottom: 1,
                          }}
                        >
                          <Typography variant="body2">{cls.title}</Typography>
                        </ListItem>
                      ))
                    ) : (
                      <Typography variant="body2">No hay clases disponibles.</Typography>
                    )}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ))
                )}
                </List>

          </Drawer>
          </>
          ) : (
            
      <Box
        sx={{
          width: {xs:"20%",md:"30%",lg:'30%'},
          height: {xs:"90%",md:"100%",lg:'90.6%'},
          backgroundColor: '#f5f5f5',
          padding: 0,
          borderRight: '1px solid #ccc',
          
          overflowY: 'auto', 
           }}>
        <Typography variant="h6">Secciones</Typography>
        <List>
          {sections.length === 0 ? (
            <Typography>No hay secciones disponibles.</Typography>
          ) : (
            sections.map((section, index) => (
              <Accordion
                key={section.id}
                expanded={selectedSection === index}
                onChange={() => {setSelectedSection(index);actualSectionName=section.title}}
                sx={{ marginBottom: 1 }}
              >
                <AccordionSummary sx={{borderBottom:'1px solid #ccc',}}>
                  <Typography variant="body1" >{section.title || section.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {section.classes && section.classes.length > 0 ? (
                      section.classes.map((cls) => (
                        <ListItem
                          key={cls.id}
                          button
                          onClick={() => {setSelectedClass(cls.id);setcontenidoC(true); calculateTimeclass(cls.id);
                            saveTimeToDatabase(cls.id);actualClassName=cls.title;actualBD=cls.DB;console.log(cls.DB);actualSectionName=section.title;console.log(actualSectionName)}}
                          sx={{
                            paddingLeft: 2,
                            backgroundColor: selectedClass === cls.id ? '#e0e0e0' : 'transparent',
                            borderRadius: 1,
                            marginBottom: 1,
                          }}
                        >
                          <Typography variant="body2">{cls.title}</Typography>
                        </ListItem>
                      ))
                    ) : (
                      <Typography variant="body2">No hay clases disponibles.</Typography>
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </List>
      </Box>)}
      </>
    ):(
      
    <Box
      sx={{
        width: '100%', 
        height: {xs:"93%",md:"100%",lg:'90%'},
        backgroundColor: '#f5f5f5',
        padding: 0,
        display:'flex',
        flexDirection:{xs:'column', md:'row',lg:'row'},
        overflowY: 'auto',
         }}>
      
      <Box
      sx={{
        width: '100%',
        height: {xs:"83%",md:"100%",lg:'90%'},
        backgroundColor: '#f5f5f5',
        padding: 0,
        display:'flex',
        flexDirection:'column',
       
         }}>

      <Box
      sx={{
        width: {xs:"99%", md:"95%",lg:'95%'},
        height: '45%',
        backgroundColor: '#fff',
        padding: 0,
        marginTop:{xs:1.5,md:3,lg:3},
        marginBottom:3,
        borderRadius:2,
        border: '1px solid #ccc',  // Borde
        boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',  // Sombra
         }}>

<Typography 
  variant="h6" 
  sx={{ 
    fontWeight: 'bold', margin: 2,
    color: 'primary.main', 
    marginBottom: 0
  }}>
  Bienvenido al curso de {mycourseName}
</Typography>

          <Typography 
  variant="body2" 
  sx={{ 
    marginBottom: 1,
    margin:2, 
    textAlign: 'justify',
  }}>
  {courseWelcome}
</Typography>

<Typography 
  variant="body3" 
  sx={{  
    color: 'primary.main', 
    marginBottom: 3 ,
    margin:2, 
    textAlign: 'justify',
  }}>
  {courseProfesor}
</Typography>
          </Box>

      <Box
      sx={{
        width: {xs:"99%", md:"95%",lg:'95%'},
        height: '60%',
        backgroundColor: '#f5f5f5',
        padding: 0,
        borderRadius: 2,
         }}>

      <Box
      sx={{
        width: {xs:"99%", md:"95%",lg:'95%'},
        height: '15%',
        backgroundColor: '#f5f5f5',
        padding: 0,
        borderRadius: 2,
         }}>
      <Typography variant="h6"  sx={{
          padding: 2,
          backgroundColor: '#008BeF',
          color: '#fff',
          textAlign: 'center',
        }}>Temario</Typography>
      </Box>
      
      <Box
      sx={{
        width: {xs:"99%", md:"95%",lg:'95%'},
        height: '84%',
        backgroundColor: '#f5f5f5',
        padding: 0,
        borderRadius: 2,
        overflowY: 'auto',
         }}>
      <List>
        {sections.length === 0 ? (
          <Typography>No hay secciones disponibles.</Typography>
        ) : (
          sections.map((section, index) => (
            <Accordion
              key={section.id}
              expanded={selectedSection === index}
              onChange={() => {setSelectedSection(index);actualSectionName=section.title;console.log(actualClassName)}}
              sx={{ marginBottom: 1 }}
            >
              <AccordionSummary  expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: '#007BFF',
              color: '#fff',
              '&:hover': { backgroundColor: '#0056b3' },
            }}>
                <Typography variant="body1">{section.title || section.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {section.classes && section.classes.length > 0 ? (
                    section.classes.map((cls) => (
                      <ListItem
                        key={cls.id}
                        button
                        onClick={() => {setSelectedClass(cls.id); oldValue(cls.id);
                          console.log(`timeOld actualizado: ${timeOld.seconds} segundos`);
                          }}
                        sx={{
                          paddingLeft: 2,
                          backgroundColor: selectedClass === cls.id ? '#007BFF' : '#fff',
                          borderRadius: 1,
                          marginBottom: 1,
                          cursor: 'pointer',
                          transition: 'background-color 0.3s, color 0.3s',
                        }}
                      >
                        <Typography variant="body2">{cls.title}</Typography>
                      </ListItem>
                    ))
                  ) : (
                    <Typography variant="body2">No hay clases disponibles.</Typography>
                  )}
                </List>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </List>
      </Box>
      </Box>
      </Box>

      <Box
      sx={{
        padding: 2,
        backgroundColor: '#f5f5f5',
        width: '30%',
        maxWidth: 400,
        
      }}
    >
      <Typography variant="h6" gutterBottom>
        Sobre el Curso:
      </Typography>
      <Typography variant="body1">
        <Link href="#docente" underline="hover" sx={{
          color: '#008BeF',
        }}>
          - Docente
        </Link>
      </Typography>
      <Typography variant="body1">
        <Link href="#bibliografia" underline="hover"sx={{
          color: '#008BeF',
        }}>
          - Bibliografía
        </Link>
      </Typography>
      <Typography variant="body1">
        <Link href="#sistema-calificacion" underline="hover"sx={{
          color: '#008BeF',
        }}>
          - Calificación
        </Link>
      </Typography>
    </Box>
    </Box>)):null}

        {/* Solo muestra el contenido de la pestaña de Secciones */}
        {selectedTab === 0 && selectedClass && (
          <Box sx={{ display:'flex', flexDirection:'column', borderRadius: 2,border: '1.5px solid #ccc',height: {xs:'82%',md:'75%',lg:'80%'}, width: {xs:'96%',sm:'85%',md:'85%',lg:'75%'},
            padding: 0, backgroundColor: '#fff',marginTop: {xs:-1,sm:1.5,md:1.5,lg:1.5}, paddingX:0 }}>
           
           <Box 
            sx={{height: '3%',
            backgroundColor: '#fff',
            paddingY: {xs:1 ,md:1 ,lg:1},
            paddingX: 1,
            borderBottom  : '1px solid #ccc', // Línea solo debajo
            display: 'flex',
            alignItems: 'center',
               }}
          >
          {/* Flecha anterior */}
          <Box 
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Button 
              onClick={handlePreviousClass} 
              disabled={!selectedClass} 
       
              startIcon={<ArrowBack />}
              sx={{
                textTransform: 'none', // Evita que el texto sea todo mayúsculas
              }}
            >
              Anterior
            </Button>
          </Box>

          {/* Título */}
          <Typography 
            variant="h6" 
            sx={{
              flex: 2,
              textAlign: 'center',
              fontWeight: 600,
            }}
          >
            Clase {(sections
  .flatMap((section) => section.classes)
  .find((cls) => cls.id === selectedClass)?.id || 0)-firstClassId+1}
          </Typography>

          {/* Flecha siguiente */}
          <Box 
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button 
              onClick={handleNextClass} 
              disabled={!selectedClass} 
       
              endIcon={<ArrowForward />}
              sx={{
                textTransform: 'none', // Evita que el texto sea todo mayúsculas
              }}
            >
              Siguiente
            </Button>
          </Box>
        </Box>

              

        <Box sx={{display:"flex", flexDirection:{xs:"column",md:"column",lg:"row"},margin:{xs:2.5,md:2.5,lg:0}, width: {xs:'91%',sm:'91.5%',md:'93%',lg:'100%'}, height: {xs:'100%',md:'100%',lg:'80%'}, marginTop:{xs:2,md:3,lg:0},  borderRadius:2, bgcolor:"#0000", justifyContent:'center', alignItems: 'center'}}>

        <Box sx={{display:"flex", flexDirection:{xs:"column",md:"column",lg:"row"}, width: {xs:'100%',md:'100%',lg:'96%'}, height: {xs:'100%',md:'100%',lg:'90%'},  borderRadius:2, boxShadow:2, bgcolor:"#f5f5f5", justifyContent:'center', alignItems: 'center',padding:1.5}}>
            <Box  sx={{
                 display: 'flex',
                 flexDirection:{xs:"column",md:"column",lg:"column"},
                 
                  height: {xs:'70%',md:'80%',lg:'90%'}, // Más altura
                  width: {xs:'100%',md:'100%',lg:'70%'},  // Ajusta al ancho disponible
                  border: '1px solid #ccc',
                  borderRadius: 0, 
                  marginTop:{xs:1,md:2,lg:2} ,
                  padding: 1,
                  backgroundColor: '#fff',

                }}>
                       
        <Typography 
            variant="h6" 
            sx={{
              display:"flex",
              padding: 0,
              textAlign: 'left',
              fontWeight: 600,
              marginLeft:1,
              marginTop:0.5,
              borderBottom:1,
            }}
          >
            {sections
              .flatMap((section) => section.classes)
              .find((cls) => cls.id === selectedClass)?.title || 'Clase no disponible'}
          </Typography> 

          <Box  sx={{
                 display: 'flex',
                 justifyContent:'center', alignItems: 'center',
                 
                  height: {xs:'70%',md:'80%',lg:'90%'}, // Más altura
                  width: {xs:'100%',md:'100%',lg:'99.6%'},  // Ajusta al ancho disponible
                 
                  borderRadius: 1, 
                  marginTop:{xs:1,md:2,lg:2} ,
                  
                  backgroundColor: '#fff',

                }}>

              {selectedClass !== null ? (
                renderContent(
                  sections
                  .flatMap((section) => section.classes) // Combina todas las clases
                  .find((cls) => cls.id === selectedClass)?.content // Busca la clase seleccionada
                )
              ) : (
                <Typography variant="body1" align="center" sx={{ padding: 2 }}>
                Contenido no disponible.
                </Typography>
                  )}
              </Box>
                  
            </Box>


            <Box sx={{display:"flex", flexDirection:"column", p:{xs:0.8,md:0.8,lg:1}, borderRadius:0, border: '1px solid #ccc', bgcolor:"#f5f5f5", width:{xs:'100%',md:'100%',lg:"30%"}, height:{xs:'30%',md:'30%',lg:"90%"}, mt:{xs:1,md:1,lg:2}}}>
               {/* Historial de conversación */}
                <Box sx={{flex:1, p:2, bgcolor:"#ffffff", borderRadius:2, mb:2, overflow:"auto", height:{xs:'100%',md:'70%',lg:"80%"}}}>
                     {messages.map((message, index) => (
                  <Box key={index} mb={1}>
                    <Typography variant="body2" color={message.sender === 'user' ? 'blue' : 'green'}>
                      <strong>{message.sender === 'user' ? 'Tú' : 'Asistente'}:</strong> {message.text}
                    </Typography>
                  </Box>
                    ))}
                </Box>

                  {/* Bandeja de entrada */}
                <Box display="flex">
                  <TextField
                    variant="outlined"
                    placeholder="Escribe tu pregunta..."
                    fullWidth
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                   />
                  <Button variant="contained" color="primary" onClick={handleSend} sx={{ ml: 1 }} disabled={isGenerating}>
                  {isGenerating ? "Generando..." : "Enviar"}
                  </Button>
                </Box>
           </Box>
           </Box>
           </Box> 
            
            
            <Box 
            sx={{height: {xs:'3%',md:'5%',lg:'3.3vh'},
            backgroundColor: '#fff',
            paddingY: 1,
            paddingX: 1,
            marginTop: {xs:2 ,md:1 ,lg:10},
            display: 'flex',
            alignItems: 'center',
               }}
          >
          {/* Flecha anterior */}
          <Box 
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              border  : '1px solid #ccc',
               
            }}
          >
            <Button 
              onClick={handlePreviousClass} 
              disabled={!selectedClass} 
       
              startIcon={<ArrowBack />}
              sx={{
                textTransform: 'none', // Evita que el texto sea todo mayúsculas
              }}
            >
              Anterior
            </Button>
          </Box>

         

          {/* Flecha siguiente */}
          <Box 
            sx={{
              flex: 1,
              border  : '1px solid #ccc',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button 
              onClick={handleNextClass} 
              disabled={!selectedClass} 
       
              endIcon={<ArrowForward />}
              sx={{
                textTransform: 'none', // Evita que el texto sea todo mayúsculas
              }}
            >
              Siguiente
            </Button>
          </Box>
        </Box>


          </Box>
        )}

          {selectedTab === 1 && (
        <CourseProgress userId={userId} courseId={mycourseId} />
      )}

      {selectedTab === 2 && (
        <ForumPage userId={userId} courseId={mycourseId} />
      )}


      {selectedTab === 3 && (
        <Homework userId={userId} courseId={mycourseId} />
      )}

{selectedTab === 4 && (
        <CourseExams userId={userId} courseId={mycourseId} />
      )}

      </Box>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert severity="success" onClose={() => setSnackbarOpen(false)}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>


    </Box>
  );
};

export default Telemedicina;
