// ExamDetails.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Card, CardContent, Radio, RadioGroup, FormControlLabel, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

const ExamDetails = ({ match }) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const { examId } = useParams();
  const myexamId = localStorage.getItem('examId');
    console.log("examId",myexamId)
  useEffect(() => {
    // Obtener las preguntas del examen
    axios.get(`http://localhost:5000/api/exam/${myexamId}/questions`)
      .then(response => setQuestions(response.data))
      .catch(err => console.error(err));
  }, [examId]);

  const handleAnswerChange = (questionId, option) => {
    setAnswers({ ...answers, [questionId]: option });
  };

  const submitAnswers = () => {
    // Enviar respuestas al backend
    axios.post(`http://localhost:5000/api/exam/${examId}/submit`, { answers })
      .then(response => alert(`Tu puntuación: ${response.data.score}`))
      .catch(err => console.error(err));
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom sx={{ marginTop: 3 }}>
        Preguntas del Examen
      </Typography>
      {questions.map(question => (
        <Card key={question.id} sx={{ marginBottom: 3 }}>
          <CardContent>
            <Typography variant="h6">{question.question_text}</Typography>
            <RadioGroup onChange={(e) => handleAnswerChange(question.id, e.target.value)}>
              {question.options.map(option => (
                <FormControlLabel key={option.id} value={option.id} control={<Radio />} label={option.option_text} />
              ))}
            </RadioGroup>
          </CardContent>
        </Card>
      ))}
      <Button variant="contained" color="primary" onClick={submitAnswers}>
        Enviar Respuestas
      </Button>
    </Container>
  );
};

export default ExamDetails;
