import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Line, Pie ,Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.register(ArcElement, Tooltip, Legend);
// Registrar las dependencias de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CourseProgress = ({ userId, courseId }) => {
  const [progressData, setProgressData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);
  
  const mycourseName = localStorage.getItem('mycourseName'); 
  const clases_length = localStorage.getItem('clases_length');

  const fetchProgressData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://universidadvirtualdelperu.edu.pe/myapp/get-course-progress/${courseId}/${userId}`);
      const data = await response.json();
      console.log("Datos recibidos:", data);
  
      if (Array.isArray(data)) {
        setProgressData(data); // Solo actualiza si es un array
      } else {
        console.error('Los datos recibidos no son un array:', data);
        setProgressData([]); // Si no es un array, establece un array vacío
      }
    } catch (error) {
      console.error('Error al obtener los datos de progreso:', error);
      setProgressData([]);
    } finally {
      setLoading(false);
    }
  };

  
  useEffect(() => {
    if (Array.isArray(progressData) && progressData.length > 0) {
      generateChartData(); // Solo llama a `generateChartData` si `progressData` tiene elementos
    }
  }, [progressData]);  // Este `useEffect` ahora depende de `progressData`
  
  const generateChartData = () => {
    const labels = progressData.map(item => item.class_title || 'Clase');
    const times = progressData.map(item => item.time || 0);
  
    setChartData({
      labels: labels,
      datasets: [
        {
          label: 'Tiempo acumulado por clase (segundos)',
          data: times,
          borderColor: '#42A5F5',
          backgroundColor: 'rgba(66, 165, 245, 0.2)',
          fill: true,
          tension: 0.4,
        },
      ],
    });
  }



  // Calculate total time and progress percentage for pie chart
  const Status = progressData.map(item => item.status || 'Status');
  const StatusTrue = progressData
  .filter(item => item.status === "True")
  .map(item => item.status);

  const nStatusTrue = StatusTrue.length;
  const progress = (nStatusTrue/ clases_length) * 100; // Porcentaje de progreso
  console.log('este es el progreso:', {progress});

  const datapie = {
    labels: ['Progreso', 'Resto'], // Etiquetas del gráfico
    datasets: [
      {
        data: [progress, 100 - progress], // Porcentaje del progreso y el resto
        backgroundColor: [ '#36A2EB','#B0B0B0'], // Colores del gráfico
        hoverBackgroundColor: ['#36A2EB', '#02B0B0'], // Colores al pasar el ratón
      },
    ],
  };
  const options = {
    responsive: true, // Hacer el gráfico responsivo
    plugins: {
      legend: {
        position: 'right', // Posición de la leyenda
      },
      tooltip: {
        enabled: true, // Habilitar tooltips
      },
    },
  };
  // Configuración para agregar el porcentaje en el centro
  const centerPercentage = {
    text: `${progress}%`,
    fontSize: '30px',
    color: '#36A2EB',
  };

  

// Paso 1: Crear un array de clases del 1 al clases_length
const totalClasses = Array.from({ length: clases_length }, (_, index) => index + 1);

// Paso 2: Crear labels e inicializarlos con las clases y los tiempos existentes
const labels = {
  label: [],
  time: []
};

// Llenamos los labels con los datos de progressData
progressData.forEach(item => {
  labels.label.push(item.class_title);  // Añadir la clase existente
  labels.time.push(item.time);  // Añadir el tiempo de la clase existente
});
labels.label = labels.label.map(classTitle => Number(classTitle));

// Paso 3: Agregar clases faltantes en labels.label y asignar tiempo 0 en labels.time
totalClasses.forEach(classTitle => {
  // Si la clase no existe en labels.label, la añadimos con tiempo 0
  if (!labels.label.includes(classTitle)) {
    labels.label.push(classTitle);
    labels.time.push(0);  // Asignamos tiempo 0 a las clases que no existen en progressData
  }
});

// Verificamos el resultado
console.log("labels", labels);



  const databar = {
    labels: labels.label,  // Etiquetas para el eje X
    datasets: [
     {
       label: 'Tiempo de Estudio (horas)',  // Etiqueta para el eje Y
       data: labels.time, // Los datos que se graficarán
        backgroundColor: 'rgba(75, 192, 192, 0.5)',  // Color de las barras
        borderColor: 'rgba(75, 192, 192, 1)',  // Color de los bordes de las barras
        borderWidth: 1,
      },
    ],
 };

  const optionsbar = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,  // Empieza desde cero en el eje Y
      },
    },
    plugins: {
      
    },
  };


  useEffect(() => {
    fetchProgressData();
  }, [userId, courseId]);

  useEffect(() => {
    generateChartData();
  }, [progressData]);  // Asegúrate de que la función se llame solo cuando `progressData` cambie

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      height: '125vh',width: '100%' ,
      border:0,
       // Para asegurarse que ocupe todo el alto de la pantalla
      backgroundColor: '#f5f5f5',
      overflowY: 'auto'  // Permitir scroll si es necesario
    }}>  <Typography variant="h3" gutterBottom sx={{marginTop:3}}>
        Progreso del curso de {mycourseName}
      </Typography>
      {/* Pie de progreso con el porcentaje */}
      <Box sx={{
        display: "flex",border:0,
        flexDirection: "row",
        borderBottom: '2px solid #ccc',
        gap: 2,
        height: "30%",  // Usar porcentaje para mantener la proporción
        width: '60%',
          
      }}>
    <Box sx={{display:"flex",  border:0, marginTop:6,
    flexDirection:"column",height:"100%", width:'50%'}}>
 <Typography 
        variant="h5" 
        align="justify"  // Justificando el texto
        sx={{ maxWidth: 600 }}
      >
        Cuanto has avanzado hasta ahora!
      </Typography>
      <Typography 
        variant="body1" 
        align="justify"  // Justificando el texto
        sx={{ maxWidth: 600 }}
      >
       Aquí puedes ver el porcentaje de clases desarrolladas. 
      </Typography>
      <Typography 
        variant="body1" 
        align="justify"  // Justificando el texto
        sx={{ maxWidth: 600 }}
      >
      Planifica tu avance!
      </Typography>
    </Box>
    <Box sx={{ display:"flex",  border:0,height:"100%", width:'50%',backgroundColor: '#f5f5f5' }}>
    <div style={{position: 'relative',
    top:'-10%',
 width: '320px', height: '320px' }}>
      <Pie data={datapie} options={options} />
      <div 
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-97.3%, -50%)',
          fontSize: centerPercentage.fontSize,
          color: centerPercentage.color,
          fontWeight: 'bold',
          backgroundColor: '#fff',
          borderRadius: '50%',
          padding: '10px',
          width: '100px',
          height: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {centerPercentage.text} {/* Número del porcentaje centrado */}
      </div>
    </div>
    </Box>
    </Box>


    <Box sx={{ display:"flex", flexDirection: 'column', height:"60%", width:'100%',backgroundColor: '#f5f5f5', marginTop:4 }}>
      <Typography variant="h5" gutterBottom>
        Tiempo por clase
      </Typography>

      {loading ? (
        <Typography variant="body1">Cargando datos de progreso...</Typography>
      ) : progressData.length > 0 ? (
      
        
        <Box sx={{ display:"flex",border:0, gap:4,flexDirection: 'row', height:"40%", width:'80%',backgroundColor: '#f5f5f5' }}>
      <Box sx={{ display:"flex", border:0, flexDirection: 'column', height:"100%", width:'30%',backgroundColor: '#f5f5f5' }}>
      <Typography 
        variant="body1" 
        align="justify"  // Justificando el texto
        sx={{ maxWidth: 600 }}
      >
        Estas son las clases a las que le dedicaste más tiempo.
      </Typography>
      </Box> 
      <Box sx={{ display:"flex", marginTop:0,flexDirection: 'column', height:"100", width:'75%',backgroundColor: '#f5f5f5',border:0 }}>
        <Line data={chartData} options={{ responsive: true }} />
        </Box>
        </Box>
        ) : (
        <Typography variant="body1">No hay datos disponibles.</Typography>
      )}
<Box sx={{ display:"flex",border:0, gap:4,flexDirection: 'row', height:"40%", width:'80%',backgroundColor: '#f5f5f5' }}>
      <Box sx={{ display:"flex", border:0, flexDirection: 'column', height:"100%", width:'30%',border:0,backgroundColor: '#f5f5f5' }}>
      <Typography 
        variant="body1" 
        align="justify"  // Justificando el texto
        sx={{ maxWidth: 600 }}
      >
        Aquí veras que clases aun te faltan
      </Typography>
      </Box> 
      <Box sx={{ display:"flex", marginTop:0,flexDirection: 'column', height:"100%", width:'75%',border:0,backgroundColor: '#f5f5f5' }}>
    <Bar data={databar} options={optionsbar} />
         </Box>
         </Box>

      
    </Box>
    
    
    </Box>
  );
  
};

export default CourseProgress;
